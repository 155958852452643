import React, { useState } from 'react';
import {Button} from "@material-ui/core";
import PropTypes from "prop-types";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {colors} from "../styles/colors";

const useStyles = makeStyles(theme => ({
    paginationButton: {
        fontWeight: 'bold',
        backgroundColor: 'white',
        boxShadow: '0px 1px 5px 0px rgb(0 0 0 / 22%);',
        borderRadius: '0',
        // border: '1px solid #282c34',
        minWidth: '10px',
        '&:hover': {
            backgroundColor: colors.navSelected,
            color: '#fff',
            boxShadow: '0px 1px 5px 0px rgb(0 0 0 / 22%);',
        }
    },
    paginationNumber: {
        maxWidth: '40px',
    },
    paginationActive: {
        backgroundColor: colors.navSelected,
        color: '#fff'
    },
}))

export default function Pagination(props:{pages:number, pagination:number, setPagination:(pagination: number) => void,
disabled:boolean}) {
    const pagination = props.pagination < 0 ? 0 : props.pagination;
    const theme = useTheme();
    const classes = useStyles(theme);
    const [activePage, setActivePage] = useState<number>(0);
    const paginationButtons = ():JSX.Element[]|null => {
        if (props.pages === 1) {return null}
        const buttons:JSX.Element[] = [];
        const maxPaginationNumbers = 7;

        // add First button if it's more than 7 pages
        if (props.pages > 7) {
            buttons.push(<Button
                key={"pg-btn-1"}
                variant="contained"
                className={`${classes.paginationButton}`}
                // disabled={pagination === 0 || props.disabled}
                onClick={()=>{props.setPagination(0)}}
            >
                First
            </Button>)
        }

        // add previous button
        buttons.push(<Button
            variant="contained"
            key={"pg-btn-2"}
            className={`${classes.paginationButton}`}
            disabled={pagination === 0 || props.disabled}
            onClick={()=>{
                document.querySelector(`[data-position="`+activePage+`"]`)?.classList.remove(classes.paginationActive)
                setActivePage(pagination-1)
                setTimeout(()=>{
                    props.setPagination(pagination-1)
                }, 100)
            }}
        >
            &lt;
        </Button>)

        let maxPagination = pagination+4 < props.pages ? pagination+4 : props.pages;
        if (pagination < 3) {
            maxPagination = maxPaginationNumbers;
        }

        if (maxPagination > props.pages) {
            maxPagination = props.pages
        }

        let startPagination = pagination > 3 ? pagination-3 : 0;
        if (maxPagination === props.pages) {
            startPagination = maxPagination - 7 > 0 ? maxPagination - 7 : 0;
        }

        for (let i=startPagination; i<maxPagination; i++) {
            buttons.push(<Button
                key={`pag-btn-${i}`}
                data-position={i}
                variant="contained"
                // disabled={props.disabled}
                className={`${classes.paginationButton} 
                    ${classes.paginationNumber} ${pagination === i ? classes.paginationActive : ''}`}
                onClick={(e) => {
                    document.querySelector(`[data-position="`+activePage+`"]`)?.classList.remove(classes.paginationActive)
                    setActivePage(i)
                    setTimeout(()=>{
                        props.setPagination(i)
                    }, 100)
                }}
            >
                {i + 1}
            </Button>)
        }

        // add next button
        buttons.push(<Button
            key={"pg-btn-3"}
            variant="contained"
            className={`${classes.paginationButton}`}
            disabled={pagination === props.pages - 1 || props.disabled}
            onClick={()=>{
                document.querySelector(`[data-position="`+activePage+`"]`)?.classList.remove(classes.paginationActive)
                setActivePage(pagination+1)
                setTimeout(()=>{
                    props.setPagination(pagination+1)
                }, 100)
                
            }}
        >
            &gt;
        </Button>)

        // add First button if it's more than 7 pages
        if (props.pages > 7) {
            buttons.push(<Button
                key={"pg-btn-4"}
                variant="contained"
                className={classes.paginationButton}
                onClick={()=>{props.setPagination(props.pages-1)}}
                // disabled={pagination === props.pages - 1 || props.disabled}
            >
                Last
            </Button>)
        }

        return buttons
    }

    return (<div>
        {paginationButtons()}
    </div>)
}

Pagination.propTypes = {
    disabled: PropTypes.bool.isRequired,
    pages: PropTypes.number.isRequired,
    pagination: PropTypes.number.isRequired,
    setPagination: PropTypes.func.isRequired,
};
